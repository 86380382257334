import React from 'react';
//import { Helmet } from "react-helmet"
import Layout from '../layout';
import { graphql } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';
import Obfuscate from 'react-obfuscate';
import SEO from '../components/SEO/SEO';

if (typeof window !== `undefined`) {
  require('bootstrap/dist/css/bootstrap.min.css');
}

export default function OurStory(props) {
  //const postEdges = props.data.allMarkdownRemark.edges;
  // const [state, setState] = React.useState({})

  return (
    <Layout>
      <SEO title="Our Story" />
      <div className="container site-content">
        <div className="row">
          <div className="col-sm-7">
            <h3 className="mb-4">
              Why do we do what we do, and why is our primary base of operation
              in Los Angeles?
            </h3>

            <p>
              Over the last couple of decades, Los Angeles has lost a great
              volume of movie and television production to other states and
              countries leaving behind a tremendous pool of talented prop
              artists.
            </p>

            <p>
              Custom Prop Shop was founded in an effort to connect these great
              artists with corporations and individuals looking to have their
              ideas fabricated at a high level of quality.
            </p>

            <p>
              <b>
                Our talent pool includes incredibly skilled prop makers,
                sculptors, metal fabricators, wood workers, scenic painters,
                mold makers and artisans from many other disciplines.
              </b>
            </p>

            <p>
              Everything we create for our clients is unique and we consider
              each a commissioned piece of art. Yes, we call them props, but
              they're all made by incredibly talented and dedicated artists.
            </p>

            <p>
              We're here to be of help to you, to help bring your ideas into
              reality. Feel free to give us a call, or{' '}
              <Obfuscate email="info@custompropshop.com">email</Obfuscate> or
              send your idea through our{' '}
              <a href="request-estimate.html">estimate request form</a>.
            </p>

            <p>We look forward to creating something fantastic for you.</p>
          </div>

          <div className="col-sm-1"></div>

          <div className="col-sm-4">
            <h5
              className="side-testimonial text-justify"
              style={{ color: 'grey' }}
            >
              "Make sure your team knows how much we appreciate all of the fine
              work accomplished in such a crunch! You fellows did an amazing job
              and came through under a crazy deadline. We really appreciate it.
              The day was a total success."
            </h5>

            <h5 className="side-testimonial text-right pb-3">
              <i>
                Rio Phior, Sagon-Phior Brand
                <br />
                Communications
              </i>
            </h5>
            <div className="text-center">
              <GatsbyImage
                image={props.data.headPushIn.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query OurStoryQuery {
    headPushIn: file(relativePath: { eq: "head-push-in.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1000, placeholder: BLURRED)
      }
    }
  }
`;
